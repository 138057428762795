<template>
  <div class="about">
    <div class="about__section">
      <div class="about__section-container">
        <div class="about__section-content">
          <h2 class="about__section-heading">A propos du projet</h2>
          <p class="about__section-text">
            Dans le but de promouvoir la transparence budgétaire et renforcer
            les relations entre les commissions parlementaires et la société
            civile, E-Tachawor est une plateforme dédiée à la diffusion de
            l’information budgétaire et le rapprochement entre les commissions
            parlementaires et la coalition marocaine pour la redevabilité, le
            but est de discuter et débattre des thématiques liées à la loi de
            finances de l’année durant tout le cycle budgétaire.
          </p>
        </div>
        <div class="about__section-img-wrapper">
          <img
            class="about__section-img"
            src="https://images.unsplash.com/photo-1573164574472-797cdf4a583a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY1MDc0Mzc1Mw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=800"
            alt=""
          />
          <img
            class="about__section-img about__section-img--small"
            src="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0NzQ1MzE5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=400"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.about {
  &__section {
    @apply py-8 lg:py-12;
    &-container {
      @apply container mx-auto px-4
      flex flex-col gap-8
      lg:flex-row lg:gap-15 lg:items-center;
    }
    &-content {
      @apply flex-1;
    }
    &-heading {
      @apply text-lg font-bold
        mb-4
        lg:mb-7 lg:text-xl;
    }
    &-text {
      @apply max-w-2xl
      text-sm sm:text-base lg:text-lg;
    }
    &-img {
      @apply rounded-md shadow-md w-[470px];
      &--small {
        @apply absolute 
          -bottom-5 -left-6
          w-[200px];
      }
      &--top{
        @apply -top-5 
      }
      &-wrapper {
        @apply relative;
      }
    }
    &-list {
      &-item {
        @apply my-2 relative pl-3;
        &:before {
          content: "";
          @apply bg-primary/60 w-1 h-1 rounded-full absolute left-0 top-1;
        }
      }
    }
    &--swapped {
      @apply bg-white;
      .about__section {
        &-content {
          @apply lg:order-2;
        }
        &-img-wrapper {
          @apply lg:order-1;
        }
      }
    }
  }
}
</style>